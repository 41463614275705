//
//
//
//
//

import Address from "./Address.vue";
import Vue from "vue";

Vue.component("Address", Address);
export default {
  name: "AddressPicker",
  model: {
    prop: "selected",
    event: "change",
  },
  props: {
    selected: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedValue: this.selected,
    };
  },
  provide() {
    return {
      getSelectedValue: this.getSelectedValue,
      setSelectedValue: this.setSelectedValue,
    };
  },
  watch: {
    selected(newVal) {
      this.selectedValue = newVal;
    },
  },
  methods: {
    getSelectedValue() {
      return this.selectedValue;
    },
    setSelectedValue(newVal) {
      const newValue = newVal;
      this.selectedValue = newValue;
      this.$emit("change", newValue);
    },
  },
};
