//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SfRadio from "@storefront-ui/vue/src/components/molecules/SfRadio/SfRadio.vue";
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";

export default {
  name: "Address",
  components: {
    SfRadio,
    SfIcon,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  inject: ["getSelectedValue", "setSelectedValue"],
  computed: {
    selected: {
      get() {
        return this.getSelectedValue();
      },
      set(newValue) {
        this.setSelectedValue(newValue);
      },
    },
  },
};
